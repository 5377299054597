/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PreheatDtoNBK } from './preheatDto';

export interface RecipeDtoNBK {
  id?: number;
  idUnique?: string;
  multilevel?: boolean;
  status?: RecipeDtoNBK.StatusEnum;
  creationLanguage?: string;
  version?: number;
  ics?: boolean;
  lastUpdate?: string;
  country?: string;
  preheat?: PreheatDtoNBK;
  deviceModel?: RecipeDtoNBK.DeviceModelEnum;
  deviceType?: RecipeDtoNBK.DeviceTypeEnum;
  kcal?: number;
  pieces?: number;
  time?: number;
  cookingMode?: RecipeDtoNBK.CookingModeEnum;
  typeId?: number;
  finishing?: RecipeDtoNBK.FinishingEnum;
  drying?: boolean;
  user?: string;
}
export namespace RecipeDtoNBK {
  export type StatusEnum = 'ACTIVE' | 'DELETED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deleted: 'DELETED' as StatusEnum
  };
  export type DeviceModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum,
    Naboo: 'NABOO' as DeviceModelEnum,
    Neo: 'NEO' as DeviceModelEnum
  };
  export type DeviceTypeEnum =
    | 'ORACS'
    | 'ORACB'
    | 'ORACXL'
    | 'NEO'
    | 'NABOO'
    | 'PUFF'
    | 'COMPACT'
    | 'AROMA'
    | 'FOODY';
  export const DeviceTypeEnum = {
    Oracs: 'ORACS' as DeviceTypeEnum,
    Oracb: 'ORACB' as DeviceTypeEnum,
    Oracxl: 'ORACXL' as DeviceTypeEnum,
    Neo: 'NEO' as DeviceTypeEnum,
    Naboo: 'NABOO' as DeviceTypeEnum,
    Puff: 'PUFF' as DeviceTypeEnum,
    Compact: 'COMPACT' as DeviceTypeEnum,
    Aroma: 'AROMA' as DeviceTypeEnum,
    Foody: 'FOODY' as DeviceTypeEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO' | 'NABOO' | 'NEO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum,
    Naboo: 'NABOO' as CookingModeEnum,
    Neo: 'NEO' as CookingModeEnum
  };
  export type FinishingEnum = 'OFF' | 'SOFT' | 'MEDIUM' | 'HARD';
  export const FinishingEnum = {
    Off: 'OFF' as FinishingEnum,
    Soft: 'SOFT' as FinishingEnum,
    Medium: 'MEDIUM' as FinishingEnum,
    Hard: 'HARD' as FinishingEnum
  };
}
