import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FolderBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { preheatConfiguration, TTemperatureUnit } from 'src/app/core/cooking-step.utils';
import { DeviceModelEnum } from 'src/app/core/utils';

@Component({
  selector: 'app-recipe-folder-modal',
  templateUrl: './recipe-folder-modal.component.html',
  styleUrls: ['./recipe-folder-modal.component.scss']
})
export class RecipeFolderModalComponent implements OnInit {

  @Output() folderUpdate: EventEmitter<FolderBoDtoNBK> = new EventEmitter();
  @Output() recipeDeleted = new EventEmitter();

  nameController: UntypedFormControl;
  preheatController: UntypedFormControl;
  
  deviceModel: DeviceModelEnum
  recipesInFolder: RecipeBoDtoNBK[];
  folder: FolderBoDtoNBK;
  isEditing: boolean = false;
  tempUnit: TTemperatureUnit = "C"
  preheatConfig = {
    floor: 30,
    ceil: 320,
    minValue: 30
  }

  constructor(public activeModal: NgbActiveModal) {}


  ngOnInit(): void {
    this.recipesInFolder = this.folder.recipes!.sort(
      (a, b) =>
        a.name!.toLocaleLowerCase() > b.name!.toLocaleLowerCase()
          ? 1
          : b.name!.toLocaleLowerCase() > a.name!.toLocaleLowerCase()
          ? -1
          : 0
    );
    this.preheatConfig = { ...preheatConfiguration(this.deviceModel, this.recipesInFolder[0].cookingMode!, this.tempUnit)!
      , minValue: this.preheatConfig.floor};

    this.nameController = new UntypedFormControl(this.folder.name, Validators.required);
    this.preheatController = new UntypedFormControl(this.folder.preheat && this.folder.preheat > this.preheatConfig.minValue 
      ? this.folder.preheat : this.preheatConfig.minValue, Validators.required);
  }

  showPreheat () {
    return this.folder.multilevel && this.recipesInFolder[0].cookingMode! !== 'NEO';
  }

  onSave() {
    this.folder.name = this.nameController.value;
    if ( this.folder.multilevel ) {
      this.folder.preheat = this.preheatController.value;
    }
    this.folderUpdate.emit(this.folder);
    this.toggleEdit();
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  onDeleteRecipe(recipe: RecipeBoDtoNBK) {
    this.recipeDeleted.emit(recipe);
  }

}
