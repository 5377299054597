import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temperatureUnit'
})
export class TemperatureUnitPipe implements PipeTransform {
  transform(value: number, ...args: string[]): unknown {
    return this.isValidvalue(value) ? this.tempConverter(value, args[0]) : undefined;
  }

  tempConverter(value: number, unit: string, roundValue?: boolean): string {
    if ( !this.isValidvalue(value) ) {
      return "--";
    }

    if ( roundValue ) {
      value = Math.round(value);
    }

    switch (unit) {
      case 'F':
        return `${value.toString()} \u00B0${unit}`;
      case 'C':
        return `${value.toString()} \u00B0${unit}`;
      default:
        return value.toString();
    }
  }

  private isValidvalue(value: any ) {
    return value !== undefined && value !== null;
  }

}
