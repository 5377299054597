<div 
    class="folder-box" 
    (click)="openFolderDetail()"
    [ngClass]="{ 'ml-folder': isMultilevel() }"
>
    <div class="folder-labels">
        <span class="recipe-counter">{{ recipeCounter() }}</span>
        <p #el [tooltip]="upperCaseTooltip(folder)" [isDisabled]="disableTooltip(el)">
            {{ folder.name }}
        </p>
    </div>
</div>

