/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FolderBoDtoNBK } from './folderBoDto';
import { ReducedDeviceDtoNBK } from './reducedDeviceDto';
import { RecipeBoDtoNBK } from './recipeBoDto';

export interface DisplayBoDtoNBK {
  id?: number;
  name?: string;
  constructor?: boolean;
  mode?: DisplayBoDtoNBK.ModeEnum;
  deviceModel: DisplayBoDtoNBK.DeviceModelEnum;
  cookingMode: DisplayBoDtoNBK.CookingModeEnum;
  lastUpdate?: string;
  devices?: Array<ReducedDeviceDtoNBK>;
  recipes?: Array<RecipeBoDtoNBK>;
  folders?: Array<FolderBoDtoNBK>;
}
export namespace DisplayBoDtoNBK {
  export type ModeEnum = 'SINGLE_DISPLAY' | 'MULTI_DISPLAY';
  export const ModeEnum = {
    SingleDisplay: 'SINGLE_DISPLAY' as ModeEnum,
    MultiDisplay: 'MULTI_DISPLAY' as ModeEnum
  };
  export type DeviceModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum,
    Naboo: 'NABOO' as DeviceModelEnum,
    Neo: 'NEO' as DeviceModelEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO' | 'NABOO' | 'NEO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum,
    Naboo: 'NABOO' as CookingModeEnum,
    Neo: 'NEO' as CookingModeEnum
  };
}
