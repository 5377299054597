<div class="modal-header">
  <div class="header" *ngIf="!isEditing">
    <div id="title">      
      <h2>{{ folder.name }}</h2>
      <span *ngIf="showPreheat()">
        <img class="preheat-img" src="assets/images/preheat_red.png" alt="Preheat" />
        {{ folder.preheat! | temperatureUnit: tempUnit }}
      </span>
    </div>
    <button
      class="button-primary name-button"
      (click)="toggleEdit()"
    >
      {{ (showPreheat() ? 'GLOBAL.EDIT': 'MY_DISPLAY.CHANGE_NAME') | translate }}
    </button>
  </div>
  <div class="header" *ngIf="isEditing">
    <div class="header-input">
      <input
        class="custom-input"
        [formControl]="nameController"
      />
      <div class="preheat" *ngIf="showPreheat()">
        <img class="" src="assets/images/preheat_red.png" alt="Preheat" />
        <app-slider
          class="w-100"
          [control]="preheatController"
          [floor]="preheatConfig.floor"
          [ceil]="preheatConfig.ceil"
          [color]="'red'"
          [temperature]="true"
          [tempUnit]="tempUnit"
          [showButton]="true"
          [step]="1"
        ></app-slider>
      </div>
    </div>
    <button
      class="button-primary name-button"
      (click)="onSave()"
    >
    {{ 'GLOBAL.SAVE' | translate }}
    </button>
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="d-flex flex-wrap">
    <app-recipe-box
      *ngFor="let r of recipesInFolder"
      [recipe]="r!"
      class="m-3"
      [canDelete]="true"
      (onDelete)="onDeleteRecipe($event)"
    ></app-recipe-box>
  </div>
</div>
